<template>

<el-container>
  <el-header style="height:auto;">
      <!-- header导航菜单开始 -->
      <el-menu
        class="menus-top"
        mode="horizontal"
        background-color="#04122D"
        text-color="#fff"
        active-text-color="#FFA6AC"
        :router="true">
        <!-- 个人中心 -->
        <el-dropdown class="menus-right" @command="oldLoginDome">
          <span class="dropdown-link">
            {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="b"
              @click.native="dialogFormVisible = true"
              icon="el-icon-aliicon-wangjimima"
              style="width: 100px; text-align: left"
              > 修改密码</el-dropdown-item
            >
            <el-dropdown-item
              command="c"
              icon="el-icon-alituichu"
              style="width: 100px; text-align: left"
              >  退 出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 标题 -->
        <label class="logosize">云物流</label>
      </el-menu>
      <!-- header导航菜单结束 -->
  </el-header>
  <el-container>
      <!-- 左侧导航子菜单开始 -->
      <el-aside class="menus-left" width="170px">
        <el-menu :router="true" :default-active="MainPage">
          <!-- 左侧一级菜单遍历显示 -->
          <el-menu-item
            v-for="(v, k) in routesSon"
            :key="k"
            :index="v.name"
            :route="{ name: v.name }"
            v-if="!v.children && v.meta.isShow == 0"
            class="menu-one">
            <i :class="v.meta.icon" style="padding-left:20px;font-size:16px;"></i>
            <span slot="title" style="font-size:14px;">{{ v.meta.title }}</span>
          </el-menu-item>
          <!-- 左侧一级菜单禁用菜单 -->
          <el-menu-item class="menu-stop" v-else-if="v.meta.isShow == 2">
            <span slot="title" style="margin-left: 25px; color: #bfbfbf">{{
              v.meta.title
            }}</span>
          </el-menu-item>
          <!-- 左侧一级菜单 增删改查菜单 -->
          <el-menu-item v-else-if="v.meta.isShow == 1" v-show="false">
          </el-menu-item>
          <!-- 左侧一级下拉菜单显示 -->
          <el-submenu
            class="submenu"
            :index="v.name"
            v-else-if="v.meta.isShow == 0">
            <template slot="title" class="submenu-bottom">
              <i :class="v.meta.icon"></i>
              <span slot="title">{{ v.meta.title }}</span>
            </template>
            <!-- 左侧二级菜单显示 -->
            <!-- <el-menu-item-group>
              <el-menu-item
                v-for="(val,is) in v.children"
                :key="is"
                :index="val.name"
                :route="{ name: val.name }"
                @click="clickselect(val)"
                ><p style="margin-left: 40px">
                  {{ val.meta.title }}
                </p></el-menu-item
              >
            </el-menu-item-group> -->
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 左侧导航子菜单结束 -->
    <el-main class="conentright">
      <router-view></router-view>
    </el-main>
  </el-container>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="35%" :before-close="BuyerhandleClose" :closeOnClickModal="false">
      <div style="padding:0 30px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
          <el-form-item v-if="accountType == true" label="选择账号" prop="id">
            <el-select v-model="ruleForm.id" placeholder="请选择账号类型" style="width:100%;" size="small">
                <el-option
                v-for="item in projectName"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原密码" prop="password">
            <el-input size="small" v-model="ruleForm.password" show-password placeholder="如忘记原密码,请在登录页使用手机验证码登录,即可重置密码."></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input size="small" v-model="ruleForm.newPassword" show-password placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="checkPass">
            <el-input size="small" v-model="ruleForm.checkPass" show-password placeholder="请再次确认新密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" size="small" style="float:right;">确 定</el-button>
            <el-button @click="resetForm('ruleForm')" size="small" style="float:right;margin-right:10px;">取 消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
</el-container>
</template>
<script>
export default {
  data() {
    return {
      accountType:false,      // 选择账号
      // 修改密码
      ruleForm: {
        id:'',  // 账号类型
        password: '',         // 原密码
        newPassword: '',      // 新密码
        checkPass: '',        // 二次新密码
      },
      rules: {
        id: [
          { required: true, message: '请选择账号', trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入原密码', trigger: 'blur'},
          { pattern: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,16}$/, message: '请输入正确格式的原密码', trigger: 'blur'},
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur'},
          { pattern: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,16}$/, message: '密码设置为 8 到 16 位至少包含数字跟字母', trigger: 'blur'},
        ],
        checkPass: [
          { required: true, message: '请再次输入新密码', trigger: 'blur'},
          { pattern: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,16}$/, message: '密码设置为 8 到 16 位至少包含数字跟字母', trigger: 'blur'},
        ]
      },
      // 账号类型
      projectName:[], 
      dialogFormVisible: false,
      MainPage:'MainPage',
      username: "管理员张三",
      //导航路由
      routesSon: [],
    };
  },
  created() {
    //获取动态路由表
    let lengths = this.$router.options.routes.length - 1;
    this.routesSon = this.$router.options.routes[lengths].children;
    this.MainPage = this.$route.matched[1].meta.routeName;
    //回显企业名称
    // this.logo = sessionStorage.getItem('companyName');
    //回显个人中心标题 
    this.username = sessionStorage.getItem('account');
  },
  beforeUpdate() {},
  methods: {
    // 修改密码提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.ruleForm.checkPass != this.ruleForm.newPassword){
            this.$message({
              message: '两次新密码不一致，请重新设置',
              type: 'warning'
            });
          }else{
            this.api.upPwd.updatePwd(this.ruleForm)
            .then(res=>{
              if(res.data.code == 999){
                  this.$message({
                    message: '修改失败,原密码错误',
                    type: 'error'
                  });
              }else if(res.data.code == 200){
                this.$message({
                    type: 'success',
                    message: "修改密码成功,请重新登录",
                    duration:500,
                    onClose:()=>{
                      sessionStorage.clear()
                      this.$router.push({name:'Login'});
                    }
                });
              }
            })

          }
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    BuyerhandleClose(){
      this.dialogFormVisible = false;
      this.ruleForm = {}
    },
    //个人中心回调
    oldLoginDome(command) {
      if (command === "a") {
      }
      if (command === "b") {
      }
      if (command === "c") {
        sessionStorage.clear();
        this.$router.push({name:'Login'});
        this.$router.go(0);
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 左上角标题
.logosize{
  outline:none;
}
// 选项卡

// header 开始
.el-menu{
  border: 0;
}
.el-header {
  padding: 0;
  label {
    font-size: 14px;
    line-height: 60px;
    color: #fff;
    float: left;
  }
  .el-submenu {
    float: right;
  }
  .el-menu-item {
    float: left;
  }
  .menus-top {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #04122D;
    .el-menu-item {
      text-align: center;
    }
    .menus-right {
      float: right;
      margin: 10px 0 0 90px;
      outline: none;
      cursor: pointer;
      .dropdown-link {
        display: inline-block;
        font-size: 14px;
        line-height: 40px;
        color: #fff;
      }
    }
    .drop-help {
      width: 100px;
      height: 60px;
      text-align: center;
      float: right;
      cursor: pointer;
      outline:none;
      .drop-link {
        font-size: 14px;
        line-height: 60px;
        vertical-align:middle; 
        color: #fff;
        i{
          font-size: 16px;
          vertical-align:middle; 
          margin-right: 8px;
        }
      }
    }
    .drop-help:hover {
      background-color: rgba(255, 166, 172, 0.3);
    }
    .menus-news {
      width: 100px;
      height: 60px;
      text-align: center;
      float: right;
      cursor: pointer;
      outline:none;
      .news-link {
        font-size: 14px;
        line-height: 60px;
        color: #fff;
        i{
          font-size: 16px;
          margin-right: 8px;
          vertical-align:middle; 
        }
      }
    }
    .menus-news:hover {
      background-color: rgba(255, 166, 172, 0.3);
    }
  }
  .el-menu--horizontal .el-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  .el-menu-item.is-active {
    border-bottom: 0 !important;
    // background-color: #c10b18 !important;
    i {
      color: #ffa6ac !important;
    }
  }
}
// header 结束
// 左侧菜单开始
.el-submenu [class^="el-icon-"] {
  color: #333 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.el-menu{
    border: 0;
}
.menus-left {
    //  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .8);
    border-right: 1px solid rgba(0, 0, 0, .06);
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 60px);
    border-top: solid 1px #e6e6e6;
    box-shadow: 2px 2px 10px rgba(128, 146, 192, 0.15);
    overflow-x: hidden;
    .el-menu-item {
        padding: 0 !important;
        span {
            color: #333;
        }
        i {
            font-weight: 400;
            font-size: 14px;
            color: #333;
        }
    }
    //禁用菜单样式开始
    .menu-stop{
      background-color: #fff;
    }
    .menu-stop:hover {
      background-color: #fff !important;
    }
    .menu-stop.is-active {
      background-color: #fff !important;
    }
    //禁用菜单样式结束
    //一级菜单样式开始
    .menu-one:hover {
      background-color: #fff !important;
    }
    .menu-one:hover span {
      color: #04122D !important;
    }
    .menu-one:hover i {
      color: #04122D !important;
    }
    .menu-one.is-active {
      background-color: #04122D !important;
      span {
        color: #fff !important;
      }
      i {
        color: #fff !important;
      }
    }
    //一级菜单样式结束
    //一级下拉菜单开始
    .submenu {
      //二级菜单开始
      .el-menu-item.is-active {
        background-color: #04122D !important;
        p {
          color: #fff !important;
        }
        i {
          color: #fff !important;
        }
      }
      //二级菜单结束
    }
    .submenu:hover span {
      color: #04122D !important;
    }
    .submenu:hover i {
      color: #04122D !important;
    }
    //一级下拉菜单结束
  }

//左侧菜单结束
// main内容开始
.el-main {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 0;
  overflow: hidden;
  
}
.conentright{
  width: 100%;
  height: 100%;
}
// mian内容结束
</style>